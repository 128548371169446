import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './CheckScreen.scss';
import config from '../../global/config';
import { getAllTaxes } from '../../store/actions/taxeAction';
import { connect } from 'react-redux';
import logoGiro from '../../assets/images/logo_giro_fundo.png';

class CheckScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      paymentType: 'credit',
      curInstallment: null,
      curInstallmentIndex: -1,
      isAcceptPolicy: false,
      isFormValid: false,
      // cardNumber: "1234567890123456",
      cardNumber: '',
      expireDate: '',
      cvvCode: '',
      cpf: '',
      card_name: '',
      // cvvCode: "997",
      isLoading: true,
      alertMsg: '',
      alertType: 'success',
      orderNumber: '',
      totalOrderAmount: 0,
      orderguid: '',
      orderChartValue: 0,
      orderChartName: '',
      orderChartRegister: 0,
      orderChartInstallment18: 0,
      // nome: '',
      documento: '',
      descricao: '',
      placaRenavam: '',
      registro: '',
      cliente: 0,
      segmento: 0,
      identificacaoCliente: '',
      statusTransacao: 0,
    };
  }

  componentDidMount() {
    let order_guid = this.props.match.params.post_id;
    let order_number = this.props.match.params.order_number;

    fetch(
      config.apiServer +
        '/api/checkout/GetOrderCheck/' +
        order_guid +
        '/' +
        order_number
    )
      .then((id) => id.json())
      .then((value) => {
        this.props.getAllTaxes([order_guid, order_number]).then((res) => {
          let total_orderr_amount = res.totalTaxes;
          this.setState({
            isLoading: false,
            orderNumber: order_number,
            orderguid: order_guid,
          });
          this.setState({
            orderChartInstallment18: res.installments[17].installmentValue,
          });
        });
        this.setState({
          totalOrderAmount: value.valor,
          cliente: value.cdCliente,
          segmento: value.cdSegmento,
          statusTransacao: value.cdStatusTransacao,
        });
        if (value.cdSegmento === 1) {
          //Despachante
          this.setState({
            // Nome: value.despachante.nome,
            documento: value.despachante.documento,
            descricao: value.despachante.descricao,
            placaRenavam: value.despachante.placaRenavam,
            registro: value.despachante.registro,
          });
        }
        if (value.cdSegmento === 4) {
          //Geral
          this.setState({
            identificacaoCliente:
              value.geral.dsIdentificacaoCliente === null
                ? ''
                : value.geral.dsIdentificacaoCliente,
          });
        }
      })
      .catch((error) => {
        this.props.getAllTaxes(0).then((res) => {
          //console.log(res)
          let total_orderr_amount = res.totalTaxes;
          this.setState({
            isLoading: false,
            orderNumber: order_number,
            orderguid: order_guid,
          });
        });
      });
  }

  onSubmit = () => {
    // if (this.state.statusTransacao === 3) {
    //     this.props.history.push('/receipt/' + this.props.match.params.post_id);
    // }
    // else {
    this.props.history.push(
      '/payment/' +
        this.props.match.params.post_id +
        '/' +
        this.props.match.params.order_number +
        '/' +
        Math.random(1)
    );
    // }
  };

  render() {
    return (
      <>
        <div className="main-area" id="main-area">
          <div className="main-section">
            <h3
              style={{
                fontWeight: 'bold',
                paddingLeft: '10px',
                paddingBottom: '30px',
              }}
            >
              Confira os dados abaixo
            </h3>

            <Row>
              {
                <Col sm={6} xs={12}>
                  <h6
                    style={{
                      fontWeight: 'bold',
                      paddingLeft: '10px',
                      paddingBottom: '30px',
                      textAlign: 'justify',
                    }}
                  >
                    Antes de continuar o pagamento, tenha a certeza de que as
                    informações abaixo estão de acordo com os serviços
                    contratados.
                  </h6>
                  <div
                    id="Despachante"
                    style={{
                      display: this.state.segmento === 1 ? 'block' : 'none',
                    }}
                  >
                    <div className="Retngulo-12">
                      <div className="Nome-Tab-Clara">
                        <label>
                          <b>CPF/CNPJ:</b> {this.state.documento}
                        </label>
                      </div>
                    </div>
                    <div className="Retngulo-12">
                      <div className="Nome-Tab-Escura">
                        <label>
                          <b>Descrição do serviço:</b> {this.state.descricao}
                        </label>
                      </div>
                    </div>
                    <div className="Retngulo-12">
                      <div className="Nome-Tab-Clara">
                        <label>
                          <b>Placa/Renavam:</b> {this.state.placaRenavam}
                        </label>
                      </div>
                    </div>
                    <div className="Retngulo-12">
                      <div className="Nome-Tab-Escura">
                        <label>
                          <b>Registro:</b> {this.state.registro}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div
                    id="Geral"
                    style={{
                      display: this.state.segmento === 4 ? 'block' : 'none',
                    }}
                  >
                    <div className="Retngulo-12">
                      <div className="Nome-Tab-Escura">
                        <label>
                          <b>Identificação do cliente:</b>{' '}
                          {this.state.identificacaoCliente}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="Retngulo-12">
                    <div className="Nome-Tab-Clara">
                      <label>
                        <b>Valor do pagamento:</b>{' '}
                        {this.state.totalOrderAmount.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </label>
                    </div>
                  </div>

                  <br></br>

                  <div className="Retngulo-12">
                    <div className="Retngulo-Parcela-12">
                      <label className="Text-Value-Installment">
                        Com a GIRO você divide em até 18x de{' '}
                        {this.state.orderChartInstallment18.toLocaleString(
                          'pt-BR',
                          { style: 'currency', currency: 'BRL' }
                        )}
                      </label>
                    </div>
                  </div>
                  <br></br>

                  <div className="confirm-button padding-top-5">
                    <button onClick={() => this.onSubmit()}>
                      <b>Avançar</b>
                    </button>
                  </div>
                </Col>
              }
              {
                <Col sm={6} xs={{ offset: 12, size: 'flex' }}>
                  <div className="Image-Center">
                    <img className="Responsive" src={logoGiro}></img>
                  </div>
                </Col>
              }
            </Row>
          </div>
        </div>
        <div className="sub-footer"></div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTaxes: (data) => dispatch(getAllTaxes(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckScreen);
