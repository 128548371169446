import config from "../../global/config"
import * as actionType from "../actions/actionTypes"

// const options = async (data) => {
//      return {
//           method: 'POST',
//           body: JSON.stringify(data),
//           headers: {
//                'Accept': 'application/json',
//                'Content-Type': 'application/json'
//           }
//      }
// }

export const sendCardInfor = (data) => {
     return dispatch => {
          return fetch(config.apiServer + '/api/PaymentValidation/api/PaymentValidation', {
               method: 'POST',
               body: JSON.stringify(data),
               headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
               }
          })
          .then(res=> res.json())
          .catch(function(error){
              console.log(error)
          })

          .then(res=> {
               data.nsu = res;
               dispatch({ type: actionType.POST_CARD_INFOR, cardInforStatus: data});
               return res;
          })
          .catch(function(error){
              console.log(error)
          })
     }
}

export const checkoutPayment = (data) => {
     return dispatch => {
          return fetch(config.apiServer + '/api/PaymentValidation/api/CheckoutPayment', {
               method: 'POST',
               body: JSON.stringify(data),
               headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
               }
          })
          .then(res=> res.json())
          .catch(function(error){
              console.log(error)
          })

          .then(res=> {
               data.nsu = res;
               dispatch({ type: actionType.POST_CARD_INFOR, cardInforStatus: data});
               return res;
          })
          .catch(function(error){
              console.log(error)
          })
     }
}

export const checkoutPaymentTest = (data) => {
     return dispatch => {
          console.log(data)
          return fetch(config.apiServer + '/api/PaymentValidation/api/PaymentValidationTest', {
               method: 'POST',
               body: JSON.stringify(data),
               headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
               }
          })
          .then(res=> res.json())
          .catch(function(error){
              console.log(error)
          })

          .then(res=> {
               data.nsu = res;
               dispatch({ type: actionType.POST_CARD_INFOR, cardInforStatus: data});
               return res;
          })
          .catch(function(error){
              console.log(error)
          })
     }
}