import React from 'react';

class NotFound extends React.Component {
    render () {
        return (
            <div className="container">
                <p>Página não encontrada</p>
            </div>
        );
    }
}

export default NotFound;