import * as actionTypes from '../actions/actionTypes';

const initialState = {
    taxeInfo: {},
};

const taxesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GOT_ALL_TAXES:
            return {
                ...state,
                taxeInfo: action.taxeInfo
            };
        default:
            return state;
    }
};

export default taxesReducer;
