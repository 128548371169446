import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import gitLoading from '../../assets/images/loader.gif';
import config from '../../global/config';
import logoGiro from '../../assets/images/logo_giro_fundo.png';
import SweetAlert from 'react-bootstrap-sweetalert';

import ReCaptcha from 'react-google-recaptcha';

const BoletosScreen = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [codigoBarras, setCodigoBarras] = useState('');

  const [debits, setDebits] = useState();
  const [message, setMessage] = useState('');
  const [message2, setMessage2] = useState('');
  const [totalPay, setTotalPay] = useState(0.0);
  const [alertMsg, setAlertMsg] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState('');

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const recaptchaRef = React.createRef();

  const getDebits = async () => {
    let capt = await recaptchaRef.current.executeAsync();

    fetch(
      config.apiServer + '/api/boletos/GetSlipInfos?barCode=' + codigoBarras,
      {
        headers: { credentials: capt },
      }
    )
      .then((id) => id.json())
      .then((result) => {
        if (result.success) setDebits(result);
        else setMessage(result.errorMessage);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showToast = (msg, type) => {
    setShowAlert(true);
    setAlertMsg(msg);
    setShowAlertType(type);
  };

  const onSubmit = async () => {
    if (codigoBarras === '') {
      showToast('Por favor, informar o código de barras', 'warning');
      return;
    }

    setIsLoading(true);
    setMessage('');
    setTotalPay(0.0);
    setDebits({});

    getDebits();
  };

  const getLink = () => {
    setIsLoading(true);

    fetch(config.apiServer + '/api/boletos/Save/' + codigoBarras, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res !== '') window.location.href = res;
        else {
          setMessage2(res.erro);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const onHandleChange = (e, field) => {
    let val = e.target.value;

    if (field === 'codigoBarras') {
      val = val.replace(/\D/g, '');

      setCodigoBarras(val);
    }
  };

  return (
    <>
      <div className="main-area" id="main-area">
        <div className="main-section">
          <Row>
            {
              <Col sm={6} xs={12}>
                {debits?.success ? (
                  <>
                    <h5
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        paddingLeft: '10px',
                        paddingBottom: '10px',
                        textAlign: 'justify',
                      }}
                    >
                      Verifique abaixo, as informações do boleto antes de
                      continuar:
                    </h5>
                    <div
                      style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        boxShadow:
                          '1px 1px 2px #999999, 0 0 25px #999999, 0 0 5px #999999',
                      }}
                    ></div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <h5>
                        <p>
                          Tipo de boleto: <b>{debits.data.billetType}</b>
                        </p>
                        {debits.data.segment && (
                          <p>
                            Segmento: <b>{debits.data.segment}</b>
                          </p>
                        )}
                        {debits.data.payeeName && (
                          <p>
                            Referência: <b>{debits.data.payeeName}</b>
                          </p>
                        )}
                        {debits.data.beneficiary.commercialName && (
                          <p>
                            Referência:{' '}
                            <b>{debits.data.beneficiary.commercialName}</b>
                          </p>
                        )}
                        <br />
                        <p>
                          Valor total dos débitos:{' '}
                          <b>
                            {parseFloat(
                              debits.data.paymentValue
                            ).toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </b>
                        </p>
                      </h5>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div
                        className="cancel-button padding-top-3"
                        style={{ textAlign: 'center' }}
                      >
                        <button
                          style={{
                            maxWidth: '120px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }}
                          onClick={() => (window.location.href = '/boletos')}
                        >
                          <b>Cancelar</b>
                        </button>
                      </div>
                      <div
                        className="confirm-button padding-top-3"
                        style={{ textAlign: 'center' }}
                      >
                        <button
                          style={{
                            maxWidth: '120px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }}
                          onClick={() => getLink()}
                        >
                          <b>Continuar</b>
                        </button>
                      </div>
                    </div>
                    <div>{message2}</div>
                  </>
                ) : (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <h6
                        style={{
                          fontWeight: 'bold',
                          paddingLeft: '10px',
                          paddingBottom: '10px',
                          textAlign: 'justify',
                        }}
                      >
                        Informe o código de barras
                      </h6>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <input
                        type="text"
                        style={{
                          textAlign: 'center',
                          maxWidth: '450px',
                          marginTop: '5px',
                          outline: 0,
                        }}
                        value={codigoBarras}
                        onChange={(e) => onHandleChange(e, 'codigoBarras')}
                        className="TextBox"
                        placeholder="Código de barras"
                        maxLength="55"
                      ></input>
                    </div>
                    <br></br>
                    <div
                      className="confirm-button padding-top-3"
                      style={{ textAlign: 'center' }}
                    >
                      <button
                        style={{ maxWidth: '600px', marginBottom: '20px' }}
                        onClick={() => onSubmit()}
                      >
                        <b>Consultar débitos</b>
                      </button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'red',
                        fontWeight: '700',
                      }}
                    >
                      {message}
                    </div>
                  </>
                )}
              </Col>
            }
            {
              <Col sm={6} xs={{ offset: 12, size: 'flex' }}>
                <div className="Image-Center">
                  <img
                    className="Responsive"
                    src={logoGiro}
                    alt="Logo Giro Pagamentos"
                  ></img>
                </div>
              </Col>
            }
          </Row>
        </div>
      </div>
      <div className="sub-footer"></div>
      {isLoading && (
        <div className="loading">
          <div className="loaderImg">
            <img src={gitLoading} alt="loading" />
            <span className="fa fa-lock" style={{ fontSize: 24 }}></span>
          </div>
          <label style={{ textAlign: 'center', marginTop: 30, width: 300 }}>
            Processando consulta.
          </label>
        </div>
      )}
      {showAlertType === 'success' ? (
        <SweetAlert
          success
          title={alertMsg}
          show={showAlert}
          onConfirm={() => setShowAlert(false)}
          onCancel={() => setShowAlert(false)}
        ></SweetAlert>
      ) : (
        <SweetAlert
          warning
          title={alertMsg}
          show={showAlert}
          onConfirm={() => setShowAlert(false)}
          onCancel={() => setShowAlert(false)}
        ></SweetAlert>
      )}
      <ReCaptcha
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Lf0ENAaAAAAAKncmTAeTKB3O9u3tDiRLxdfqG7z"
      />
    </>
  );
};

export default BoletosScreen;
