import React from 'react';
import { Row, Col } from 'reactstrap';
import config from '../../global/config';
import { getAllTaxes } from '../../store/actions/taxeAction';
import { connect } from 'react-redux';
import logoGiro from '../../assets/images/logo_giro_fundo.png';

class DetranESCheckScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      paymentType: 'credit',
      curInstallment: null,
      curInstallmentIndex: -1,
      isAcceptPolicy: false,
      isFormValid: false,
      cardNumber: '',
      expireDate: '',
      cvvCode: '',
      cpf: '',
      card_name: '',
      isLoading: true,
      alertMsg: '',
      alertType: 'success',
      orderNumber: '',
      totalOrderAmount: 0,
      orderguid: '',
      orderChartValue: 0,
      orderChartName: '',
      orderChartRegister: 0,
      orderChartInstallment12: 0,
      documento: '',
      descricao: '',
      cliente: 0,
      placa: '',
      veiculo: 0,
      proprietario: '',
      valor12x: 0.0,
      debitos: [],
      valorTotalDebitos: '',
      statusTransacao: 0,
      guid: '',
    };
  }

  componentDidMount() {
    let order_guid = this.props.match.params.post_id;
    let guid = this.props.match.params.guid;
    this.setState({ guid: guid });

    fetch(
      config.apiServerDetran +
        '/api/DetranES/ConsultaDebitosDetran/' +
        order_guid
    )
      .then((id) => id.json())
      .then((value) => {
        this.setState({
          proprietario: value.nome,
          veiculo: value.identificacaoVeiculo,
          placa: value.placa,
          valor12x: value.valor12x,
          debitos: value.detalhesDebitos,
          valorTotalDebitos: value.valorTotal,
          statusTransacao: value.cdStatusTransacao,
        });
      })
      .catch((error) => {
        //console.log(res)
        this.setState({
          isLoading: false,
          orderguid: order_guid,
        });
      });
  }

  onSubmit = () => {
    let order_guid = this.props.match.params.post_id;
    fetch(
      config.apiServerDetran +
        '/api/DetranES/SalvarTransacao/' +
        order_guid +
        '/' +
        this.state.guid
    )
      .then((id) => id.json())
      .then((response) => {
        window.location =
          response[0].replace('/check/', '/payment/') + '/' + this.state.guid;
        // console.log(response[0].replace('/check/', '/payment/') + '/' + this.state.guid)
      })
      .catch((error) => {
        console.log('Erro: ' + error);
        this.setState({
          isLoading: false,
          orderguid: order_guid,
        });
      });
  };

  render() {
    return (
      <>
        <div className="main-area" id="main-area">
          <div className="main-section">
            <h3
              style={{
                fontWeight: 'bold',
                paddingLeft: '10px',
                paddingBottom: '20px',
              }}
            >
              Confira os dados abaixo
            </h3>

            <Row>
              {
                <Col sm={6} xs={12}>
                  <h6
                    style={{
                      fontWeight: 'bold',
                      paddingLeft: '10px',
                      paddingBottom: '10px',
                      textAlign: 'justify',
                    }}
                  >
                    Antes de continuar o pagamento, tenha a certeza de que as
                    informações abaixo estão de acordo com os serviços
                    contratados.
                  </h6>
                  <div style={{ marginLeft: '20px' }}>
                    <p>
                      <b>Proprietário:</b> {this.state.proprietario}
                    </p>
                    <p>
                      <b>Placa:</b> {this.state.placa}
                    </p>
                    <p>
                      <b>Veículo:</b> {this.state.veiculo}
                    </p>
                  </div>
                  <div
                    style={{
                      maxHeight: '150px',
                      overflowY: 'auto',
                      marginLeft: '40px',
                    }}
                  >
                    {this.state.debitos.map((debito, index) => (
                      <li key={index}>
                        {debito.descricao} - R${' '}
                        {debito.valor.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </li>
                    ))}
                  </div>
                  <br></br>
                  <div style={{ marginLeft: '20px' }}>
                    <p>
                      <b>Valor total dos débitos:</b> R${' '}
                      {this.state.valorTotalDebitos.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </p>
                  </div>

                  <div className="Retngulo-12">
                    <div className="Retngulo-Parcela-12">
                      <label className="Text-Value-Installment">
                        {/* Com a GIRO você divide em até 12x de R$ {this.state.valor12x.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} */}
                        Com a GIRO você divide em até 18x
                      </label>
                    </div>
                  </div>
                  <br></br>

                  <div className="confirm-button padding-top-5">
                    <button onClick={() => this.onSubmit()}>
                      <b>Avançar</b>
                    </button>
                  </div>
                </Col>
              }
              {
                <Col sm={6} xs={{ offset: 12, size: 'flex' }}>
                  <div className="Image-Center">
                    <img
                      className="Responsive"
                      src={logoGiro}
                      alt="Logo Giro Pagamentos"
                    ></img>
                  </div>
                </Col>
              }
            </Row>
          </div>
        </div>
        <div className="sub-footer"></div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTaxes: (data) => dispatch(getAllTaxes(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetranESCheckScreen);
