import React from 'react';
import { checkoutPaymentTest } from '../../store/actions/cardAction';
import { connect } from 'react-redux';
import { getAllTaxes } from '../../store/actions/taxeAction';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Row, Col } from 'reactstrap';
import Radio from '@material-ui/core/Radio';
import redcircle from '../../assets/images/redcircle.png';
import visa from '../../assets/images/visa.png';
import hipercard from '../../assets/images/hipercard.png';
import backet from '../../assets/images/backet.png';
import elo from '../../assets/images/elo.png';
import gitLoading from '../../assets/images/loader.gif';
import credit_front from '../../assets/images/credit_front.png';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import checkicon from '../../assets/images/check.png';
import config from '../../global/config';

class PaymentTestScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      showAlertToken: false,
      showAlertTokenInvalido: false,
      showAlertPayment: false,
      paymentType: 'credit',
      curInstallment: null,
      curInstallmentIndex: -1,
      isAcceptPolicy: false,
      cardNumber: '',
      expireDate: '',
      cvvCode: '',
      cpf: '',
      email: '',
      card_name: '',
      isLoading: true,
      alertMsg: '',
      alertType: 'success',
      orderNumber: '',
      totalOrderAmount: 0,
      orderguid: '',
      statusTransacao: 0,
      validatedFone: false,
      fone: '',
      ip: '',
      postalCode: '',
      address1: '',
      addressNumber: '',
      address2: '',
      city: '',
      neigborhood: '',
      uf: '',
      guid: '',
      showInstallments: true,
      showPayments: false,
      installments: [],
      taxes: [],
    };
  }

  componentDidMount() {
    let order_guid = this.props.match.params.post_id;
    let order_number = this.props.match.params.order_number;

    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      'https://services.giropagamentos.com.br/api/cebi/h/GetMetadata/' +
        order_guid,
      requestOptions
    )
      .then((response) => response.json())
      .then((value) => {
        this.setState({
          totalOrderAmount: value.Valor,
        });
        fetch(config.apiServer + '/api/Taxes/GetTaxesCreaSP/' + value.Valor + '/1004')
            .then(res => res.json())
            .then(res => {
                this.setState({installments: res.installments,
                  taxes:res});
            })
      })
      .catch((error) => console.log('error', error));

    this.setState({ isLoading: false });

    this.getIP();
  }

  onShowPayments = () => {
    if (
      this.state.curInstallment === null &&
      this.state.paymentType !== 'debit'
    ) {
      this.showToast('Por favor, selecione a parcela', 'warning');
      return;
    }

    this.setState(() => {
      return { showPayments: true, showInstallments: false };
    });
  };

  showToast = (msg, type) => {
    this.setState({ showAlert: true, alertMsg: msg, alertType: type });
  };

  onConfirm = () => {
    this.setState({ showAlert: false });
  };

  onConfirmToken = (response) => {
    let order_guid = this.props.match.params.post_id;
    let token = response;

    fetch(
      config.apiServer +
        '/api/Checkout/ConfirmTokenToValidate/' +
        order_guid +
        '/' +
        token
    )
      .then((id) => id.json())
      .then((value) => {
        if (value === true) {
          this.setState({ showAlertToken: false, validatedFone: true });
        } else {
          this.setState({ showAlertTokenInvalido: true });
          setTimeout(
            () => this.setState({ showAlertTokenInvalido: false }),
            3000
          );
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          orderguid: order_guid,
        });
      });
  };

  async getIP() {
    await fetch('https://api.ipify.org?format=json')
      .then((id) => id.json())
      .then((response) => {
        this.setState({ ip: response.ip });
      });
  }

  onSendTokenToValidate = () => {
    if (this.state.fone === '') {
      this.showToast('Por favor, informar o telefone', 'warning');
      return;
    }

    if (this.state.fone.length < 10) {
      this.showToast(
        'Por favor, informar um número de telefone válido',
        'warning'
      );
      return;
    }

    let order_guid = this.props.match.params.post_id;

    if (!this.state.validatedFone) {
      this.setState({ showAlertToken: true });
    }

    fetch(
      config.apiServer +
        '/api/Checkout/SendTokenToValidate/' +
        order_guid +
        '/' +
        this.state.fone +
        '/' +
        this.state.ip
    )
      .then((id) => id.json())
      .then((value) => {
        // this.setState({});
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          orderguid: order_guid,
        });
      });
  };

  onCancelToken = () => {
    this.setState({ showAlertToken: false });
  };

  onCancelPayment = () => {
    this.setState({ showAlertPayment: false });
  };

  onCancel = () => {
    this.setState({ showAlert: false });
  };

  onValidateFone = () => {
    if (!this.state.validatedFone) {
      this.setState({ showAlertToken: true });
    }
  };

  setCurInstallment = (curInstallment, curInstallmentIndex) => {
    this.setState({ curInstallment, curInstallmentIndex });
  };

  onSubmit = () => {
    let { cardNumber } = this.state;
    let { cvvCode } = this.state;
    let { card_name } = this.state;
    let { cpf } = this.state;
    let { email } = this.state;
    let { postalCode } = this.state;
    let { address1 } = this.state;
    let { city } = this.state;
    let { uf } = this.state;
    let { neigborhood } = this.state;
    let { expireDate } = this.state;
    let paymentMethod = this.state.paymentType === 'debit' ? 1 : 2;
    let cardRegEx = new RegExp(/^[0-9]{16}$/g);
    let codeRegEx = new RegExp(/^[0-9]{3}$/g);
    let strs = this.state.expireDate.split('/');

    if (
      this.state.curInstallment === null &&
      this.state.paymentType !== 'debit'
    ) {
      this.showToast('Por favor, selecione a parcela', 'warning');
      return;
    }
    if (cardNumber === '') {
      this.showToast('Por favor, informar o número do cartão', 'warning');
      return;
    }
    if (card_name === '') {
      this.showToast('Por favor, informar nome impresso no cartão', 'warning');
      return;
    }
    if (cpf === '') {
      this.showToast('Por favor, informar CPF do titular do cartão', 'warning');
      return;
    }
    if (expireDate === '') {
      this.showToast(
        'Por favor, informar o Data de validade do cartão',
        'warning'
      );
      return;
    }
    if (cvvCode === '') {
      this.showToast('Por favor, informar o CVV do cartão', 'warning');
      return;
    }
    if (postalCode === '') {
      this.showToast('Por favor, informar o CEP do endereço', 'warning');
      return;
    }
    if (!uf) {
      this.showToast('Por favor, informar o Estado', 'warning');
      return;
    }
    if (uf?.length !== 2) {
      this.showToast('Por favor, informar o Estado corretamente', 'warning');
      return;
    }
    if (!address1) {
      this.showToast('Por favor, informar o Endereço', 'warning');
      return;
    }
    if (!neigborhood) {
      this.showToast('Por favor, informar o Bairro', 'warning');
      return;
    }
    if (!city) {
      this.showToast('Por favor, informar a Cidade', 'warning');
      return;
    }
    if (email === '') {
      this.showToast('Por favor, informar o E-mail', 'warning');
      return;
    }

    // if (!this.state.validatedFone) {
    //   this.showToast('Por favor, valide o telefone', 'warning');
    //   return;
    // }

    this.setState({ isLoading: true });

    if (cardRegEx.test(cardNumber)) {
      if (strs.length === 2) {
        let expireMonth = strs[0];
        let expireYear = strs[1];
        if (Math.floor(expireMonth < 13) && Math.floor(expireYear) > 19) {
          if (codeRegEx.test(cvvCode)) {
            const data = {
              idOrder: this.props.match.params.post_id,
              orderNumber: this.props.match.params.order_number,
              customerOrderNumber: this.state.orderNumber,
              paymentMethod: paymentMethod,
              totalOrder: this.state.totalOrderAmount,
              installments: this.state.curInstallment
                ? this.state.curInstallment.month
                : '1',
              installmentsValue: this.state.curInstallment
                ? this.state.curInstallment.installmentValue
                : this.state.totalOrderAmount,
              email: email,
              fingerprintSessionId: this.state.fingerprintSessionId,
              cardData: {
                idCard: '',
                cardNumber: cardNumber,
                CardName: card_name,
                CpfNumber: cpf,
                monthValid: expireMonth,
                yearExpiration: expireYear,
                cvvCode: cvvCode,
                telefone: this.state.fone,
                address1: this.state.address1,
                addressNumber: this.state.addressNumber,
                address2: this.state.address2,
                neigborhood: this.state.neigborhood,
                city: this.state.city,
                postalCode: this.state.postalCode,
                state: this.state.uf,
              },
              nsu: '',
            };
            this.props.checkoutPaymentTest(data).then((res) => {
              console.log(res)
              if (res.authorize) {
                this.props.history.push(
                  '/receipttest/' + this.props.match.params.post_id
                );
              } else {
                this.showToast(res.errorMessage, 'warning');
              }
              this.setState({ isLoading: false });
            });
          } else {
            this.showToast('CVV inválido', 'warning');
            return;
          }
        } else {
          this.showToast('Data de validade inválida', 'warning');
          return;
        }
      } else {
        this.showToast('Data de validade inválida', 'warning');
        return;
      }
    } else {
      this.showToast('Número do cartão inválido', 'warning');
      return;
    }
  };

  validateNumericInput(e) {
    if (/^\d+$/.test(e.toString())) {
      return true;
    } else {
      return false;
    }
  }

  onHandleChange = (e, field) => {
    let val = e.target.value;
    if (
      field === 'cvvCode' ||
      field === 'cardNumber' ||
      field === 'expireDate' ||
      field === 'cpf' ||
      field === 'fone' ||
      field === 'postalCode' ||
      field === 'addressNumber'
    ) {
      if (this.validateNumericInput(e.target.value)) {
        this.setState({ [field]: val }, () => {});
      } else {
        if (e.target.value === '') {
          this.setState({ [field]: val }, () => {});
        }
      }
    } else {
      this.setState({ [field]: val }, () => {});
    }

    if (field === 'expireDate') {
      if (e.target.value.length === 4) {
        if (e.target.value.indexOf('/') > -1) {
          val = e.target.value.replace('/', '');
          this.setState({ [field]: val }, () => {});
        } else {
          val =
            e.target.value.toString().substring(0, 2) +
            '/' +
            e.target.value.toString().substring(2, 4);
          this.setState({ [field]: val }, () => {});
        }
      }
    }

    if (field === 'postalCode') {
      if (e.target.value?.length === 8) {
        fetch(`https://viacep.com.br/ws/${e.target.value}/json/`)
          .then((res) => res.json())
          .then((data) =>
            this.setState({
              neigborhood: data.bairro,
              city: data.localidade,
              uf: data.uf,
              address1: data.logradouro,
            })
          )
          .catch((error) => {
            this.setState({
              neigborhood: '',
              city: '',
              uf: '',
              address1: '',
            });
          });
      } else {
        this.setState({
          neigborhood: '',
          city: '',
          uf: '',
          address1: '',
        });
      }
    }
  };
  render() {
    let taxeInfo = this.props.taxes;
    let installments1,
      installments2 = [];
    if (this.state.taxes.installments) {
      installments1 = this.state.taxes.installments.slice(0, 6);
      installments2 = this.state.taxes.installments.slice(6);
    }
    let { isAcceptPolicy, isLoading, showInstallments, showPayments } =
      this.state;
    return (
      <>

        <div className="main-area" id="main-area">
          <div className="main-section">
            <Row>
              {this.state.paymentType === 'credit' ? (
                <Col
                  sm={3}
                  xs={12}
                  className={'p-0 '}
                  style={{ marginBottom: -20 }}
                >
                  <h4
                    style={{
                      fontWeight: 'bold',
                      paddingLeft: '10px',
                      paddingBottom: '30px',
                    }}
                  >
                    Forma de Pagamento:
                  </h4>
                  <label style={{ display: 'inline' }}>
                    <Radio
                      color="primary"
                      onClick={() => this.setState({ paymentType: 'credit' })}
                      checked={this.state.paymentType === 'credit'}
                    ></Radio>
                    Crédito
                  </label>
                  {
                    installments1 &&
                    installments1.map((item, key) => (
                      <div
                        key={key}
                        className={
                          'check-area ' +
                          (key === this.state.curInstallmentIndex
                            ? 'active'
                            : '')
                        }
                        onClick={() => this.setCurInstallment(item, key)}
                      >
                        <Radio
                          color="primary"
                          checked={key === this.state.curInstallmentIndex}
                        ></Radio>
                        <div className="check-text">
                          <h6>
                            {item.month}x{' '}
                            {item.installmentValue.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </h6>
                        </div>
                      </div>
                    ))}
                </Col>
              ) : (
                <Col
                  sm={3}
                  xs={12}
                  className="p-0"
                  style={{ marginBottom: -20 }}
                >
                  <label style={{ display: 'inline' }}>
                    <Radio
                      color="primary"
                      onClick={() => this.setState({ paymentType: 'credit' })}
                      checked={this.state.paymentType === 'credit'}
                    ></Radio>
                    Crédito
                  </label>
                  <div className="check-area">
                    <Radio color="primary" checked={true}></Radio>
                    <div className="check-text">
                      <h6>
                        à vista R${' '}
                        {taxeInfo
                          ? taxeInfo.debitValue.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })
                          : ''}
                      </h6>
                    </div>
                  </div>
                </Col>
              )}
              {this.state.paymentType === 'credit' && (
                <Col sm={3} xs={12} className="p-0">
                  <label
                    className="blank_label"
                    style={{ paddingTop: '95px' }}
                  ></label>
                  {installments2 &&
                    installments2.map((item, key) => (
                      <div
                        key={key}
                        className={
                          'check-area ' +
                          (key + 6 === this.state.curInstallmentIndex
                            ? 'active'
                            : '')
                        }
                        onClick={() => this.setCurInstallment(item, key + 9)}
                      >
                        <Radio
                          color="primary"
                          checked={key + 9 === this.state.curInstallmentIndex}
                        ></Radio>
                        <div className="check-text">
                          <h6>
                            {item.month}x{' '}
                            {item.installmentValue.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </h6>
                        </div>
                      </div>
                    ))}
                </Col>
              )}

              <Col sm={6} xs={12}>
                <div className="card-detail">
                  <h5 style={{ fontWeight: 'bold' }}>
                    Preencher os dados do cartão:
                  </h5>
                  <div className="card-type-img padding-top-20">
                    <h5 style={{ fontWeight: 'bold' }}>Cartão do Crédito:</h5>
                    <div className="img-area">
                      <img src={redcircle} alt=""></img>
                      <img src={visa} alt="visa"></img>
                      <img src={hipercard} alt="hipercard"></img>
                      <img src={elo} alt="elo"></img>
                    </div>
                  </div>
                  <div
                    className="card-number padding-top-20"
                    style={{ position: 'relative' }}
                  >
                    <input
                      inputMode="numeric"
                      value={this.state.cardNumber}
                      placeholder="Número do cartão*"
                      style={{ paddingLeft: 34 }}
                      onChange={(e) => this.onHandleChange(e, 'cardNumber')}
                      name={'cardNumber'}
                      maxLength={16}
                    ></input>
                    <span
                      className="fa fa-credit-card"
                      style={{
                        fontSize: 20,
                        position: 'absolute',
                        left: 8,
                        bottom: 12,
                      }}
                    ></span>
                  </div>
                  <div className="card-other padding-top-20">
                    <div>
                      <input
                        autoCapitalize="words"
                        value={this.state.card_name}
                        placeholder="Nome impresso no cartão*"
                        onChange={(e) => this.onHandleChange(e, 'card_name')}
                        maxLength={45}
                      ></input>
                    </div>
                    <div>
                      <input
                        inputMode="numeric"
                        placeholder="CPF do titular do cartão*"
                        value={this.state.cpf}
                        onChange={(e) => this.onHandleChange(e, 'cpf')}
                        maxLength={14}
                      ></input>
                    </div>
                  </div>
                  <div className="card-other padding-top-20">
                    <div>
                      <input
                        inputMode="numeric"
                        placeholder="Data de validade*"
                        value={this.state.expireDate}
                        onChange={(e) => this.onHandleChange(e, 'expireDate')}
                        maxLength={5}
                      ></input>
                    </div>
                    <div>
                      {/* <h6 style={{ fontWeight: "bold" }}></h6> */}
                      <input
                        inputMode="numeric"
                        style={{ width: 'calc(100% - 50px)' }}
                        value={this.state.cvvCode}
                        placeholder="Código de segurança*"
                        onChange={(e) => this.onHandleChange(e, 'cvvCode')}
                        maxLength={3}
                      ></input>
                      <span style={{ padding: 10 }}>
                        <OverlayTrigger
                          trigger="click"
                          placement={'left'}
                          overlay={
                            <Popover id={`popover-positioned-left`}>
                              <Popover.Content
                                style={{ padding: 6, fontWeight: 500 }}
                              >
                                <Row>
                                  <Col className="text-center">
                                    <img
                                      style={{ verticalAlign: 'top' }}
                                      src={credit_front}
                                      alt="Credit Card Front"
                                    />
                                    <br />
                                    Frente
                                    <br /> 4 digitos
                                  </Col>
                                  <Col className="text-center">
                                    <span
                                      style={{ fontSize: 20 }}
                                      className="fa fa-credit-card"
                                    ></span>
                                    <br />
                                    Verso
                                    <br /> 3 digitos
                                  </Col>
                                </Row>
                                <Row>
                                  O seu CVV pode estar na frente ou no verso do
                                  seu cartao
                                </Row>
                              </Popover.Content>
                            </Popover>
                          }
                        >
                          <span
                            className="fa fa-info-circle"
                            style={{ fontSize: 24, color: 'gray' }}
                          ></span>
                        </OverlayTrigger>
                      </span>
                    </div>
                  </div>
                  <div className="card-other padding-top-20">
                    <div>
                      <input
                        inputMode="numeric"
                        placeholder="CEP*"
                        value={this.state.postalCode}
                        onChange={(e) => this.onHandleChange(e, 'postalCode')}
                        maxLength={8}
                      ></input>
                    </div>
                    <div>
                      <input
                        value={this.state.uf}
                        placeholder="Estado*"
                        onChange={(e) => this.onHandleChange(e, 'uf')}
                        maxLength={2}
                      ></input>
                    </div>
                  </div>
                  <div className="card-number padding-top-20">
                    <div>
                      <input
                        value={this.state.address1}
                        placeholder="Endereço*"
                        onChange={(e) => this.onHandleChange(e, 'address1')}
                        maxLength={100}
                      ></input>
                    </div>
                  </div>
                  <div className="card-other padding-top-20">
                    <div>
                      <input
                        placeholder="Número"
                        value={this.state.addressNumber}
                        onChange={(e) =>
                          this.onHandleChange(e, 'addressNumber')
                        }
                        maxLength={6}
                      ></input>
                    </div>
                    <div>
                      <input
                        value={this.state.address2}
                        placeholder="Complemento"
                        onChange={(e) => this.onHandleChange(e, 'address2')}
                        maxLength={20}
                      ></input>
                    </div>
                  </div>
                  <div className="card-other padding-top-20">
                    <div>
                      <input
                        placeholder="Bairro*"
                        value={this.state.neigborhood}
                        onChange={(e) => this.onHandleChange(e, 'neigborhood')}
                        maxLength={50}
                      ></input>
                    </div>
                    <div>
                      <input
                        value={this.state.city}
                        placeholder="Cidade*"
                        onChange={(e) => this.onHandleChange(e, 'city')}
                        maxLength={50}
                      ></input>
                    </div>
                  </div>
                  <div className="card-number padding-top-20">
                    <div>
                      <input
                        inputMode="email"
                        value={this.state.email}
                        placeholder="E-mail*"
                        onChange={(e) => this.onHandleChange(e, 'email')}
                        maxLength={80}
                      ></input>
                    </div>
                  </div>
                  {/* <div className="card-other padding-top-20">
                    <div>
                      <input
                        inputMode="numeric"
                        value={this.state.fone}
                        placeholder="Telefone*"
                        onChange={(e) => this.onHandleChange(e, 'fone')}
                        maxLength={11}
                      ></input>
                    </div>
                    {this.state.validatedFone === false ? (
                      <div className="confirm-button">
                        <button onClick={() => this.onSendTokenToValidate()}>
                          Validar telefone
                        </button>
                      </div>
                    ) : (
                      <div>telefone validado</div>
                    )}
                  </div> */}
                  <div className="check-button-area padding-top-20">
                    <div
                      className="checkbox"
                      onClick={() =>
                        this.setState({ isAcceptPolicy: !isAcceptPolicy })
                      }
                    >
                      {isAcceptPolicy && (
                        <img src={checkicon} alt="Check Icon" />
                      )}
                    </div>
                    <label htmlFor="inlineCheckbox1">
                      {' '}
                      Concordo com a política de privacidade, os dados <br />
                      coletados serão utilizados apenas para esta transação.
                    </label>
                  </div>
                  <div className="confirm-button padding-top-5">
                    <button
                      disabled={!this.state.isAcceptPolicy}
                      onClick={() => this.onSubmit()}
                    >
                      <b>Realizar Pagamento</b>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="sub-footer">
          <div className="total-amount">
            <img src={backet} alt="backet"></img>
            <h5 style={{ margin: 0, paddingLeft: '20px' }}>
              {' '}
              {this.state.totalOrderAmount.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </h5>
          </div>
        </div>
        {isLoading && (
          <div className="loading">
            <div className="loaderImg">
              <img src={gitLoading} alt="loading" />
              <span className="fa fa-lock" style={{ fontSize: 24 }}></span>
            </div>
            <label style={{ textAlign: 'center', marginTop: 30, width: 300 }}>
              Processando pagamento aguarde até ser direcionado a página de
              confirmação.
            </label>
          </div>
        )}
        {this.state.alertType === 'success' ? (
          <SweetAlert
            success
            title={this.state.alertMsg}
            show={this.state.showAlert}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          ></SweetAlert>
        ) : (
          <SweetAlert
            warning
            title={this.state.alertMsg}
            show={this.state.showAlert}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          ></SweetAlert>
        )}
        {
          <SweetAlert
            input
            title="Token"
            show={this.state.showAlertToken}
            onConfirm={(response) => this.onConfirmToken(response)}
            showCancel
            onCancel={this.onCancelToken}
            confirmBtnText="Validar token"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            closeOnClickOutside={false}
          >
            Insira o token que você recebeu por SMS
          </SweetAlert>
        }
        {
          <SweetAlert
            danger
            title="Token inválido!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            // timeout={2000}
            showConfirm={false}
            show={this.state.showAlertTokenInvalido}
          ></SweetAlert>
        }
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkoutPaymentTest: (cardData) => dispatch(checkoutPaymentTest(cardData)),
    getAllTaxes: (data) => dispatch(getAllTaxes(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTestScreen);
