import * as actionTypes from './actionTypes';
import config from '../../global/config';

// const options = async (data) => {
//     return {
//         headers: {
//             // 'Authorization': 'Bearer ' + await AsyncStorage.getItem('jwtToken'),
//             'Content-Type': 'application/json',
//             'Accept': 'application/json'
//         },
//         method: 'post',
//         body: JSON.stringify(data)
//     };
// };

export const getAllTaxesCreaSP = (data) => {
    return dispatch => {
        return fetch(config.apiServer + '/api/Taxes/GetTaxesCreaSP/' + data[0] + '/' + data[1])
            .then(res => res.json())
            .then(res => {
                dispatch({ type: actionTypes.GOT_ALL_TAXES, taxeInfo: res });
                return res;
            })
    };
};

export const getAllTaxes = (data) => {
    return dispatch => {
        return fetch(config.apiServer + '/api/Taxes/GetTaxes/' + data[0] + '/' + data[1])
            .then(res => res.json())
            .then(res => {
                dispatch({ type: actionTypes.GOT_ALL_TAXES, taxeInfo: res });
                return res;
            })
    };
};
