import React from 'react'
import { Row, Col } from "reactstrap";
import logoGiro from '../../assets/images/logo_giro_fundo.png';
// import checkGreen from '../../assets/images/check.png';
import "./CreaROScreen.scss";

class CreaROScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <div className="main-area" id="main-area">
                    <div className="main-section">
                        <h3
                            style={{
                                fontWeight: "bold",
                                paddingLeft: "10px",
                                paddingBottom: "30px"
                            }}
                        >
                            Preencha os campos abaixo
                        </h3>

                        <Row>
                            {
                                <Col sm={6} xs={12}>
                                    <h6
                                        style={{
                                            fontWeight: "bold",
                                            paddingLeft: "10px",
                                            paddingBottom: "30px",
                                            textAlign: "justify"
                                        }}
                                    >
                                        Para realizar o pagamento, será necessário que você nos informe as informações abaixo.
                                    </h6>

                                    <div>
                                        <input style={{ paddingLeft: 34 }} className="TextBox" placeholder="CPF/CNPJ (somente dígitos)" ></input>
                                    </div>
                                    <br></br>
                                    <div>
                                        <input style={{ paddingLeft: 34 }} className="TextBox" placeholder="E-mail" ></input>
                                    </div>
                                    <br></br>
                                    <div>
                                        <input type='text' style={{ paddingLeft: 34 }} className="TextBox" placeholder="Código de barras (somente dígitos)" ></input>
                                    </div>

                                    <br></br>

                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div className="Label-Valor" >
                                            <label className="Label-Texto-Valor">R$ 3.450,00</label>
                                        </div>
                                    </div>

                                    <br></br>

                                    <div className="confirm-button padding-top-5">
                                        <button onClick={() => this.onSubmit()}><b>Avançar</b></button>
                                    </div>

                                </Col>

                            }
                            {
                                <Col sm={6} xs={{ offset: 12, size: 'flex' }}>
                                    <div className="Image-Center">
                                        <img className="Responsive" alt="Logo Giro Pagamentos" src={logoGiro}></img>
                                    </div>
                                </Col>
                            }
                        </Row>

                    </div>
                </div>
                <div className="sub-footer"></div>
            </>
        )
    }

}

export default CreaROScreen;