import * as actionType from '../actions/actionTypes'

const initialState = {
     cardInforStatus: {}
}

const cardReducer = (state = initialState, action) => {
     switch (action.type) {
          case actionType.POST_CARD_INFOR:
               return {
                    ...state,
                    cardInforStatus: action.cardInforStatus
               };
          default:
               return state;
     }
}

export default cardReducer;