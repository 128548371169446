import React from 'react';
// import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import "./RegisterScreen.scss";

class RegisterScreen extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        return (
            <>
                <div className="main-area" id="register">
                    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Col md={7} sm={12}>
                            {/* <div className="m-main-section"> */}
                            <div className="card-detail">
                                <div className='personal-infor'>
                                    <input placeholder="Nome completo*" />
                                </div>
                                <div className="card-other padding-top-20">
                                    <div>
                                        <input value={this.state.card_name} placeholder="CPF*" onChange={(e) => this.onHandleChange(e, "card_name")} maxLength={45} ></input>
                                    </div>
                                    <div>
                                        <input placeholder="Telefone*" value={this.state.cpf} onChange={(e) => this.onHandleChange(e, "cpf")} maxLength={14}></input>
                                    </div>
                                </div>
                                <div className='personal-infor padding-top-20'>
                                    <input placeholder="E-mail*" />
                                </div>
                                <div className="card-other padding-top-20">
                                    <div>
                                        <input value={this.state.card_name} placeholder="CEP*" onChange={(e) => this.onHandleChange(e, "card_name")} maxLength={45} ></input>
                                    </div>
                                </div>
                                <div className='personal-infor padding-top-20'>
                                    <input placeholder="Endereço*" />
                                </div>
                                <div className="card-other padding-top-20">
                                    <div>
                                        <input value={this.state.card_name} placeholder="Número" onChange={(e) => this.onHandleChange(e, "card_name")} maxLength={45} ></input>
                                    </div>
                                    <div>
                                        <input placeholder="Complemento" value={this.state.cpf} onChange={(e) => this.onHandleChange(e, "cpf")} maxLength={14}></input>
                                    </div>
                                </div>
                                <div className="card-other padding-top-20">
                                    <div>
                                        <input value={this.state.card_name} placeholder="Bairro*" onChange={(e) => this.onHandleChange(e, "card_name")} maxLength={45} ></input>
                                    </div>
                                    <div>
                                        <input placeholder="Cidade*" value={this.state.cpf} onChange={(e) => this.onHandleChange(e, "cpf")} maxLength={14}></input>
                                    </div>
                                    <div>
                                        <input placeholder="Estado*" value={this.state.cpf} onChange={(e) => this.onHandleChange(e, "cpf")} maxLength={14}></input>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
}

export default RegisterScreen;