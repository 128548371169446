import React from "react";
// import logo from "../../assets/images/logo.png";
import './HeaderComponent.scss';
class HeaderComponent extends React.Component {
    render() {
        return (
            <div className="header-area">
                {/* <a href="/"><img src={logo} /></a> */}
            </div>
        );
    }
}

export default HeaderComponent