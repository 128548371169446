import React, { Component } from 'react';
import { connect } from 'react-redux';
import './ReceiptCreaScreen.scss';
import { sendCardInfor } from '../../store/actions/cardAction';
import { getAllTaxes } from '../../store/actions/taxeAction';
import { Col, Row } from 'reactstrap';
import { Radio } from '@material-ui/core';
import giro from '../../assets/images/giro.png';
import SweetAlert from 'react-bootstrap-sweetalert';
const qs = require('query-string');

// const pdfUrl = "http://localhost:5000";
const pdfUrl = 'https://services.giropagamentos.com.br';

function download(strData, strFileName, strMimeType) {
  var D = document,
    A = arguments,
    a = D.createElement('a'),
    d = A[0],
    n = A[1],
    t = A[2] || 'application/pdf';
  var newdata = 'data:' + strMimeType + ';base64,' + escape(strData);
  //build download link:
  a.href = newdata;
  if ('download' in a) {
    a.setAttribute('download', n);
    a.innerHTML = 'downloading...';
    D.body.appendChild(a);
    setTimeout(function () {
      var e = D.createEvent('MouseEvents');
      e.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
      D.body.removeChild(a);
    }, 40);
    return true;
  }
}

class ReceiptCreaScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idOrder: '',
      telefone: '',
      email: props.email,
      method: 'email',
      invoiceId: '',
      qtdInstallments: '',
      valueInstallments: '',
      optCredDeb: '',
      cards: props.cards.cardInforStatus,
      showAlert: false,
      alertMsg: '',
      alertType: 'success',
    };
    this.handleInputChange = this.handleInputChange.bind(this);

    //console.log(props)
    console.log(this);
  }

  componentDidMount() {
    let order_id = this.state.cards.customerOrderNumber;

    fetch(pdfUrl + '/api/creasp/GetOrderCheckoutByCdCarrinho/' + order_id)
      .then((id) => id.json())
      .then((value) => {
        this.setState({ email: value.email });
      })
      .catch((error) => {});

    // // console.log(this.props.getParam('id'));
    // console.log(qs.parse(this.props.location.search));
    let invoiceId = qs.parse(this.props.location.search).id;
    this.setState({
      invoiceId,
      //qtdInstallments : this.props.cards.carInforStatus.installments,
      //valueInstallments : this.props.cards.carInforStatus0.totalOrder,

      //util para receber a url
      //cards : this.props.location.search,
    });
  }

  showToast = (msg, type) => {
    //console.log('here;')
    this.setState({ showAlert: true, alertMsg: msg, alertType: type });
  };

  onConfirm = () => {
    this.setState({ showAlert: false });
  };

  onCancel = () => {
    this.setState({ showAlert: false });
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;

    if (name === 'telefone') {
      if (/^\d+$/.test(target.value) || target.value === '') {
        this.setState({
          [name]: target.value,
        });
      }

      //this.onTextChangedCardNumber(event.value)
    } else {
      this.setState({
        [name]: target.value,
      });
    }
  }

  enviarEmailSMS = () => {
    let invoiceId = this.state.invoiceId;
    let email = this.state.email;
    let telefone = this.state.telefone;

    const data = {
      idOrder: invoiceId,
      telefone: telefone,
      email: email,
    };
    if (!invoiceId) return;
    fetch(pdfUrl + '/api/receipt/SendReceipt/', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        this.showToast('E-mail enviado.', 'warning');
        return;
      })
      .catch(function (error) {
        //console.log(error)
      });
  };

  downloadPdf = () => {
    let invoiceId = this.state.invoiceId;
    if (!invoiceId) return;
    this.setState({ isDownloading: true });

    fetch(pdfUrl + '/api/receipt/GetReceipt/' + invoiceId)
      .then((id) => id.json())
      .then((res) => {
        download(res, invoiceId + '.pdf', 'application/pdf');
        this.setState({ isDownloading: false });
      });
  };

  render() {
    //console.log(this.state.cards)
    return (
      <div className="main-area" id="receipt">
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Col md={7} sm={12} className="mb-1">
            <div className="m-main-section">
              <h4 style={{ fontWeight: 'bold' }}>
                Pagamento realizado com sucesso!
              </h4>
              <div className="padding-top-20">
                <h5>
                  {' '}
                  Quer compartilhar o comprovante de pagamento com outra pessoa
                  ou deseja reenviar através de outra opção
                </h5>
              </div>
              <label className="pr-0">
                <Radio
                  color="primary"
                  onClick={() => this.setState({ method: 'email' })}
                  checked={this.state.method === 'email'}
                ></Radio>
                Por E-mail
              </label>
              {/* <label>
                                <Radio
                                    color="primary"
                                    onClick={() => this.setState({ method: "whatsapp" })}
                                    checked={this.state.method === "whatsapp"}
                                ></Radio>
                                Por Whatsapp
                    </label> */}
              {this.state.method === 'email' ? (
                <div className="personal-infor">
                  <h6 style={{ paddingBottom: '20px' }}>
                    Digite seu e-mail para receber o comprovante de pagamento:
                  </h6>
                  <input
                    placeholder={'E-mail'}
                    name="email"
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    maxLength={50}
                  ></input>
                </div>
              ) : (
                <div className="personal-infor">
                  <h6 style={{ paddingBottom: '20px' }}>
                    Digite o número do seu celular para receber o comprovante de
                    pagamento:
                  </h6>
                  <input
                    name="telefone"
                    placeholder={'(99)9 9999 9999)'}
                    onChange={this.handleInputChange}
                    value={this.state.telefone}
                    maxLength={11}
                  ></input>
                </div>
              )}

              <div className="padding-top-20 submit-arear">
                <button
                  onClick={() => {
                    this.enviarEmailSMS();
                  }}
                >
                  Enviar
                </button>
              </div>
              <h6>
                Ou, clique no botão abaixo e faça o download do comprovante de
                pagamento.
              </h6>
              <div className="padding-top-20 down-arear">
                <button
                  className="btn"
                  onClick={() => {
                    this.downloadPdf();
                  }}
                >
                  Download PDF do comprovante
                </button>
                {this.state.isDownloading && <span>Downloading...</span>}
              </div>
            </div>
          </Col>

          <Col md={4} sm={12}>
            <div className="infor-section">
              <div className="sub-header">
                <h5 style={{ margin: 0 }}>Informações de Pagamento</h5>
              </div>
              <div className="personal-card-infor">
                <h5>Forma de pagamento:</h5>
                <h5>
                  {this.state.cards.paymentMethod === 2 ? 'Crédito' : 'Débito'}
                </h5>
              </div>
              <div className="personal-card-infor">
                <h5>Quantidade de parcelas:</h5>
                <h5>{this.state.cards.installments}</h5>
              </div>
              <div className="personal-card-infor">
                <h5>Valor das parcelas:</h5>
                {/* <h5>{this.state.cards.installmentsValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </h5> */}
              </div>
              <div className="personal-card-infor">
                <h5>Código de autorização:</h5>
                <h5>{this.state.invoiceId}</h5>
              </div>
            </div>
            <div className="sub-logo-area">
              <img src={giro} alt="Logo Giro Pagamentos"></img>
              <h6 style={{ paddingTop: '10px' }}>
                <a href="https://www.giropagamentos.com.br" target="_blank">
                  Clique aqui
                </a>
                , para conhecer
              </h6>
              <h6>uma solucao de paramento na</h6>
              <h6>medida certa para o seu negocio.</h6>
            </div>
          </Col>
        </Row>
        {this.state.alertType === 'success' ? (
          <SweetAlert
            success
            title={this.state.alertMsg}
            show={this.state.showAlert}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          ></SweetAlert>
        ) : (
          <SweetAlert
            warning
            title={this.state.alertMsg}
            show={this.state.showAlert}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          ></SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCardInfor: (cardData) => dispatch(sendCardInfor(cardData)),
    getAllTaxes: () => dispatch(getAllTaxes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptCreaScreen);
