import React, { Component } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import CardScreen from './screens/CardScreen/CardScreen';
import ReceiptCreaScreen from './screens/ReceiptScreen/ReceiptCreaScreen';
import ReceiptScreen from './screens/ReceiptScreen/ReceiptScreen';

import CreaSPScreen from './screens/CreaSPScreen/CreaSPScreen';
import CreaROScreen from './screens/CreaROScreen/CreaROScreen';

import DetranESScreen from './screens/DetranESScreen/DetranESScreen';
import DetranESCheckScreen from './screens/DetranESScreen/DetranESCheckScreen';

import DetranPEScreen from './screens/DetranPEScreen/DetranPEScreen';

import DetranSPScreen from './screens/DetranSPScreen/DetranSPScreen';

import CheckScreen from './screens/CheckScreen/CheckScreen';
import PaymentScreen from './screens/PaymentScreen/PaymentScreen';
import RegisterScreen from './screens/RegisterScreen/RegisterScreen';
import NotFound from './screens/NotFound/NotFound';
import PrefeituraSantosScreen from './screens/PrefeituraSantosScreen/PrefeituraSantosScreen';
import BoletosScreen from './screens/BoletosScreen/BoletosScreen';
import CondoBlueScreen from './screens/CondoBlueScreen/CondoBlueScreen';
import CebiScreen from './screens/CebiScreen/CebiScreen';
import PaymentTestScreen from './screens/PaymentScreen/PaymentTestScreen';
import ReceiptTestScreen from './screens/ReceiptScreen/ReceiptTestScreen';

class AppNavigator extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Redirect exact from="/" to="/" />
          <Route exact path="/creasp" component={CreaSPScreen} />
          <Route exact path="/crearo" component={CreaROScreen} />
          <Route exact path="/detranes" component={DetranESScreen} />
          {/* <Route exact path="/detranescheck" component={DetranESCheckScreen} />
          <Route exact path="/detransp" component={DetranSPScreen} />
          <Route exact path="/detranpe" component={DetranPEScreen} /> */}
          {/* <Route
            exact
            path="/prefeitura/santos"
            component={PrefeituraSantosScreen}
          /> */}
          <Route exact path="/prefeitura/santos" component={BoletosScreen} />
          {/* <Route exact path="/boletos" component={BoletosScreen} /> */}
          <Route exact path="/check" component={CheckScreen} />
          <Route exact path="/card" component={CardScreen} />
          <Route exact path="/payment" component={PaymentScreen} />
          <Route exact path="/receiptcrea" component={ReceiptCreaScreen} />
          <Route exact path="/receipt" component={ReceiptScreen} />
          <Route exact path="/register" component={RegisterScreen} />
          <Route path="/card/:post_id/:order_number" component={CardScreen} />
          <Route
            path="/creasp/:post_id/:order_number"
            component={CreaSPScreen}
          />
          {/* <Route path="/condoblue/:post_id" component={CondoBlueScreen} />
          <Route path="/cebi/:post_id" component={CebiScreen} /> */}
          <Route path="/check/:post_id/:order_number" component={CheckScreen} />
          <Route
            path="/payment/:post_id/:order_number/:guid"
            component={PaymentScreen}
          />
          <Route path="/paymenttest/:post_id" component={PaymentTestScreen} />
          <Route
            path="/receipt/:post_id/:order_number"
            component={ReceiptScreen}
          />
          <Route
            path="/detranescheck/:post_id"
            component={DetranESCheckScreen}
          />
          <Route path="/register/:post_id" component={RegisterScreen} />
          <Route component={NotFound} />
        </Switch>
      </div>
    );
  }
}

export default AppNavigator;
