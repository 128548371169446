const config = {
  secretKey: 'secret',
  // apiServer: 'http://localhost:5002',
  apiServerDetran: 'https://localhost:44306',
  // apiServerDetranSP: 'https://localhost:44306',
  apiServer: 'https://services.giropagamentos.com.br',
  // apiServerDetran: 'https://detranservices.giropagamentos.com.br',
  apiServerDetranSP: 'https://giro-daycoval.azurewebsites.net',
};

export default config;
