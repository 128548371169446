import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import taxesReducer from './store/reducers/taxesReducer';
import cardReducer from './store/reducers/cardReducer';
import { BrowserRouter as Router } from 'react-router-dom';
import AppNavigator from './AppNavigator';
import HeaderComponent from './components/Header/HeaderComponent';
import FooterComponent from './components/Footer/FooterComponent';
const rootReducer = combineReducers({
    taxes: taxesReducer,
    cards: cardReducer,
  })
  
const store = createStore(rootReducer, applyMiddleware(thunk));
  

const app = (
    <Provider store={store}>
        <HeaderComponent/>
        <Router>
            <AppNavigator />
        </Router>
        <FooterComponent/>
    </Provider>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
