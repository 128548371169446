import React from 'react';
import { connect } from 'react-redux';
import './ReceiptCreaScreen.scss';
import { sendCardInfor } from '../../store/actions/cardAction';
import { getAllTaxes } from '../../store/actions/taxeAction';
import { Col, Row } from 'reactstrap';
import { Radio } from '@material-ui/core';
import giro from '../../assets/images/giro.png';
import SweetAlert from 'react-bootstrap-sweetalert';
import gitLoading from '../../assets/images/loader.gif';

// const pdfUrl = 'http://localhost:5002';
const pdfUrl = 'https://services.giropagamentos.com.br';

function download(strData, strFileName, strMimeType) {
  var D = document,
    A = arguments,
    a = D.createElement('a'),
    n = A[1] || 'application/pdf';
  var newdata = 'data:' + strMimeType + ';base64,' + escape(strData);
  //build download link:
  a.href = newdata;
  if ('download' in a) {
    a.setAttribute('download', n);
    a.innerHTML = 'downloading...';
    D.body.appendChild(a);
    setTimeout(function () {
      var e = D.createEvent('MouseEvents');
      e.initMouseEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
      D.body.removeChild(a);
    }, 40);
    return true;
  }
}

class ReceiptScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idOrder: '',
      telefone: '',
      method: 'email',
      invoiceId: '',
      qtdInstallments: '',
      valueInstallments: '',
      optCredDeb: '',
      cards: props.cards.cardInforStatus,
      showAlert: false,
      alertMsg: '',
      alertType: 'success',
      email: '',
      whatsapp: '',
      cdFormapagamento: 0,
      qtdParcelas: 0,
      autorizacao: '',
      recibo: null,
      valorParcela: 0,
      statusTransacao: 0,
      isLoading: true,
      valor: 0,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    let nsu = this.props.match.params.post_id;
    fetch(pdfUrl + '/api/checkout/GetOrder/' + nsu)
      .then((id) => id.json())
      .then((value) => {
        if (value === null) this.props.history.push('/undefined/');

        this.setState({
          // totalOrderAmount: value.valor,
          email: value.email === null ? '' : value.email,
          whatsapp: value.whatsapp === null ? '' : value.whatsapp,
          cdFormapagamento: value.cdTipoPagamento,
          qtdParcelas: value.qtdParcelas,
          autorizacao: value.autorizacao,
          recibo: value.recibo === null ? null : value.recibo,
          valorParcela: value.valorParcela,
          invoiceId: nsu,
          statusTransacao: value.cdStatusTransacao,
          valor: value.valor,
        });

        if (this.state.statusTransacao !== 3) {
          this.props.history.push('/undefined/');
          //this.props.history.push('/check/' + this.props.match.params.post_id + '/' + this.props.match.params.order_number);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.setState({ isLoading: false });
  }

  showToast = (msg, type) => {
    this.setState({ showAlert: true, alertMsg: msg, alertType: type });
  };

  onConfirm = () => {
    this.setState({ showAlert: false });
  };

  onCancel = () => {
    this.setState({ showAlert: false });
  };

  handleInputChange(event) {
    const target = event.target;
    const name = target.name;

    if (name === 'telefone') {
      if (/^\d+$/.test(target.value) || target.value === '') {
        this.setState({
          [name]: target.value,
        });
      }
    } else {
      this.setState({
        [name]: target.value,
      });
    }
  }

  enviarEmailSMS = () => {
    let invoiceId = this.state.invoiceId;
    let email = this.state.email;
    let telefone = this.state.whatsapp;

    const data = {
      idOrder: invoiceId,
      OrderNumber: this.props.match.params.order_number,
      IdOrder: this.props.match.params.post_id,
      telefone: telefone,
      email: email,
    };

    this.setState({ isLoading: true });

    if (!invoiceId) return;
    fetch(pdfUrl + '/api/receipt/SendReceipt/', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        this.showToast('Recibo enviado.', 'warning');
        return;
      })
      .catch(function (error) {
        //console.log(error)
        this.setState({ isLoading: false });
      });
  };

  downloadPdf = () => {
    let invoiceId = this.state.invoiceId;
    let cd = this.props.match.params.order_number;
    if (!invoiceId) return;
    this.setState({ isDownloading: true, isLoading: true });

    fetch(pdfUrl + '/api/receipt/GetReceipt/' + invoiceId + '/' + cd)
      .then((id) => id.json())
      .then((res) => {
        download(res, invoiceId + '.pdf', 'application/pdf');
        this.setState({ isDownloading: false, isLoading: false });
      });
  };

  render() {
    let { isLoading } = this.state;
    return (
      <>
        <div className="main-area" id="receipt">
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Col md={7} sm={12} className="mb-1">
              <div className="m-main-section">
                <h4 style={{ fontWeight: 'bold' }}>
                  Pagamento realizado com sucesso!
                </h4>
                <div className="padding-top-20">
                  <h5>
                    {' '}
                    Quer compartilhar o comprovante de pagamento com outra
                    pessoa ou deseja reenviar através de outra opção
                  </h5>
                </div>
                <label className="pr-0">
                  <Radio
                    color="primary"
                    onClick={() => this.setState({ method: 'email' })}
                    checked={this.state.method === 'email'}
                  ></Radio>
                  Por E-mail
                </label>
                {/* <label>
                                <Radio
                                    color="primary"
                                    onClick={() => this.setState({ method: "whatsapp" })}
                                    checked={this.state.method === "whatsapp"}
                                ></Radio>
                                Por Whatsapp
                    </label> */}
                {this.state.method === 'email' ? (
                  <div className="personal-infor">
                    <h6 style={{ paddingBottom: '20px' }}>
                      Digite seu e-mail para receber o comprovante de pagamento:
                    </h6>
                    <input
                      placeholder={'E-mail'}
                      name="email"
                      onChange={this.handleInputChange}
                      value={this.state.email}
                      maxLength={50}
                    ></input>
                  </div>
                ) : (
                  <div className="personal-infor">
                    <h6 style={{ paddingBottom: '20px' }}>
                      Digite o número do seu celular para receber o comprovante
                      de pagamento:
                    </h6>
                    <input
                      name="whatsapp"
                      placeholder={'(99) 99999 9999'}
                      onChange={this.handleInputChange}
                      value={this.state.whatsapp}
                      maxLength={11}
                    ></input>
                  </div>
                )}

                <div className="padding-top-20 submit-arear">
                  <button
                    onClick={() => {
                      this.enviarEmailSMS();
                    }}
                  >
                    Enviar
                  </button>
                </div>
                <h6>
                  Ou, clique no botão abaixo e faça o download do comprovante de
                  pagamento.
                </h6>
                <div className="padding-top-20 down-arear">
                  <button
                    className="btn"
                    onClick={() => {
                      this.downloadPdf();
                    }}
                  >
                    Download PDF do comprovante
                  </button>
                  {this.state.isDownloading && <span>Downloading...</span>}
                </div>
              </div>
            </Col>

            <Col md={4} sm={12}>
              <div className="infor-section">
                <div className="sub-header">
                  <h5 style={{ margin: 0 }}>Informações de Pagamento</h5>
                </div>
                <div className="personal-card-infor">
                  <h5>Forma de pagamento:</h5>
                  <h5>
                    {this.state.cdFormapagamento === 2 ? 'Crédito' : 'Débito'}
                  </h5>
                </div>
                <div className="personal-card-infor">
                  <h5>Quantidade de parcelas:</h5>
                  <h5>{this.state.qtdParcelas}</h5>
                </div>
                <div className="personal-card-infor">
                  <h5>Valor das parcelas:</h5>
                  <h5>
                    {(this.state.valor / this.state.qtdParcelas).toLocaleString(
                      'pt-BR',
                      { style: 'currency', currency: 'BRL' }
                    )}{' '}
                  </h5>
                </div>
                <div className="personal-card-infor">
                  <h5>Código de autorização:</h5>
                  <h5>{this.state.autorizacao}</h5>
                </div>
              </div>
              {/* <div className="sub-logo-area">
                <img src={giro} alt="Logo Giro Pagamentos"></img>
                <h6 style={{ paddingTop: '10px' }}>
                  <a href="https://www.giropagamentos.com.br" target="_blank">
                    Clique aqui
                  </a>
                  , para conhecer
                </h6>
                <h6>uma solução de pagamento na</h6>
                <h6>medida certa para o seu negócio.</h6>
              </div> */}
              <div>
                {' '}
                <div className="Image-Center">
                  <h1 style={{ paddingBottom: '20px', paddingTop: '55px' }}>
                    <div
                      style={{ color: '#ee3562', fontWeight: '700' }}
                      href="https://lp.giropagamentos.com.br/consulta-debitos"
                      target="_blank"
                    >
                      IPVA 2024
                    </div>
                  </h1>
                  <h4 style={{ paddingBottom: '25px' }}>
                    <a
                      style={{ color: 'black', fontWeight: '600' }}
                      href="https://lp.giropagamentos.com.br/consulta-debitos"
                      target="_blank"
                    >
                      Clique aqui e parcele em até
                      <span style={{ color: '#ee3562', fontWeight: '750' }}>
                        {' '}
                        18x
                      </span>
                    </a>
                  </h4>
                  <h6>
                    Ou acesse{' '}
                    <a href="https://www.giropagamentos.com.br" target="_blank">
                      giropagamentos.com.br
                    </a>{' '}
                    para saber mais
                  </h6>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {isLoading && (
          <div className="loading">
            <div className="loaderImg">
              <img src={gitLoading} alt="loading" />
              <span className="fa fa-lock" style={{ fontSize: 24 }}></span>
            </div>
            <label style={{ textAlign: 'center', marginTop: 30, width: 300 }}>
              Processando recibo, aguarde.
            </label>
          </div>
        )}
        {this.state.alertType === 'success' ? (
          <SweetAlert
            success
            title={this.state.alertMsg}
            show={this.state.showAlert}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          ></SweetAlert>
        ) : (
          <SweetAlert
            warning
            title={this.state.alertMsg}
            show={this.state.showAlert}
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
          ></SweetAlert>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendCardInfor: (cardData) => dispatch(sendCardInfor(cardData)),
    getAllTaxes: () => dispatch(getAllTaxes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptScreen);
