import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './CondoBlueScreen.scss';
import config from '../../global/config';
import { getAllTaxesCreaSP } from '../../store/actions/taxeAction';
import { connect } from 'react-redux';
import logoGiro from '../../assets/images/logo_giro_fundo.png';

class CondoBlueScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: false,
      paymentType: 'credit',
      curInstallment: null,
      curInstallmentIndex: -1,
      isAcceptPolicy: false,
      isFormValid: false,
      cardNumber: '',
      expireDate: '',
      cvvCode: '',
      cpf: '',
      card_name: '',
      isLoading: true,
      alertMsg: '',
      alertType: 'success',
      orderNumber: '',
      totalOrderAmount: 0,
      orderguid: '',
      orderChartValue: 0,
      orderChartName: '',
      orderChartRegister: 0,
      orderChartInstallment12: 0,
      clientDoc: '',
      CNPJ: '',
      nameCond: '',
      description: '',
    };
  }

  componentDidMount() {
    let order_guid = this.props.match.params.post_id;
    let order_number = this.props.match.params.order_number;

    var myHeaders = new Headers();

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(
      'https://services.giropagamentos.com.br/api/condoblue/h/GetMetadata/' +
        order_guid,
      requestOptions
    )
      .then((response) => response.json())
      .then((value) => {
        this.setState({
          orderChartValue: value.Valor,
          orderChartName: value.PagadorNome,
          orderChartRegister: value.PagadorDocumento,
          description: value.DescricaoDebitos,
          CNPJ: value.CondominioCNPJ,
          nameCond: value.CondominioNome,
          clientDoc: value.PagadorDocumento,
        });
        this.props.getAllTaxesCreaSP([value.Valor, 1004]).then((res) => {
          let total_orderr_amount = res.totalTaxes;
          this.setState({
            isLoading: false,
            orderNumber: order_number,
            orderguid: order_guid,
            totalOrderAmount: total_orderr_amount,
          });
          this.setState({
            orderChartInstallment12: res.installments[11].installmentValue,
          });
        });
      })
      .catch((error) => console.log('error', error));
  }

  onSubmit = () => {
    this.props.history.push(
      '/card/' +
        this.props.match.params.post_id +
        '/' +
        this.props.match.params.order_number
    );
  };

  render() {
    return (
      <>
        <div className="main-area" id="main-area">
          <div className="main-section">
            <h3
              style={{
                fontWeight: 'bold',
                paddingLeft: '10px',
                paddingBottom: '30px',
              }}
            >
              Confira os dados abaixo
            </h3>

            <Row>
              {
                <Col sm={6} xs={12}>
                  <h6
                    style={{
                      fontWeight: 'bold',
                      paddingLeft: '10px',
                      paddingBottom: '30px',
                      textAlign: 'justify',
                    }}
                  >
                    Antes de continuar o pagamento, tenha a certeza de que as
                    informações abaixo estão de acordo com os serviços
                    contratados.
                  </h6>

                  <div className="Retngulo-12">
                    <div className="Nome-Tab-Escura">
                      <label>
                        <b>Nome:</b> {this.state.orderChartName}
                      </label>
                    </div>
                  </div>
                  <div className="Retngulo-12">
                    <div className="Nome-Tab-Clara">
                      <label>
                        <b>Número do documento:</b> {this.state.clientDoc}
                      </label>
                    </div>
                  </div>
                  <div className="Retngulo-12">
                    <div className="Nome-Tab-Escura">
                      <label>
                        <b>CNPJ:</b> {this.state.CNPJ}
                      </label>
                    </div>
                  </div>
                  <div className="Retngulo-12">
                    <div className="Nome-Tab-Clara">
                      <label>
                        <b>Nome do condomínio:</b> {this.state.nameCond}
                      </label>
                    </div>
                  </div>
                  <div className="Retngulo-12">
                    <div className="Nome-Tab-Escura">
                      <label>
                        <b>Valor do Pagamento:</b>{' '}
                        {this.state.orderChartValue.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </label>
                    </div>
                  </div>
                  <div className="Retngulo-12">
                    <div className="Nome-Tab-Clara">
                      <label>
                        <b>Descrição:</b> {this.state.description}
                      </label>
                    </div>
                  </div>

                  <br></br>

                  <div className="Retngulo-12">
                    <div className="Retngulo-Parcela-12">
                      <label className="Text-Value-Installment">
                        Com a GIRO você divide em até 18x
                        {/* de{' '}
                        {this.state.orderChartInstallment18.toLocaleString(
                          'pt-BR',
                          { style: 'currency', currency: 'BRL' }
                        )} */}
                      </label>
                    </div>
                  </div>
                  <br></br>

                  <div className="confirm-button padding-top-5">
                    {/* <button onClick={() => this.onSubmit()}> */}
                    <button>
                      <b>Avançar</b>
                    </button>
                  </div>
                </Col>
              }
              {
                <Col sm={6} xs={{ offset: 12, size: 'flex' }}>
                  <div className="Image-Center">
                    <img
                      className="Responsive"
                      src={logoGiro}
                      alt="Logo Giro Pagamentos"
                    ></img>
                  </div>
                  <div className="Image-Center">
                    <h1 style={{ paddingBottom: '20px', paddingTop: '65px' }}>
                      <div
                        style={{ color: '#ee3562', fontWeight: '700' }}
                        href="https://lp.giropagamentos.com.br/consulta-debitos"
                        target="_blank"
                      >
                        IPVA 2024
                      </div>
                    </h1>
                    <h4 style={{ paddingBottom: '20px' }}>
                      <a
                        style={{ color: 'black', fontWeight: '600' }}
                        href="https://lp.giropagamentos.com.br/consulta-debitos"
                        target="_blank"
                      >
                        Clique aqui e parcele em até
                        <span style={{ color: '#ee3562', fontWeight: '750' }}>
                          {' '}
                          18x
                        </span>
                      </a>
                    </h4>
                    <h6>
                      Ou acesse{' '}
                      <a
                        href="https://www.giropagamentos.com.br"
                        target="_blank"
                      >
                        giropagamentos.com.br
                      </a>{' '}
                      para saber mais
                    </h6>
                  </div>
                </Col>
              }
            </Row>
          </div>
        </div>
        <div className="sub-footer"></div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    taxes: state.taxes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTaxesCreaSP: (data) => dispatch(getAllTaxesCreaSP(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CondoBlueScreen);
