import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'reactstrap';
import gitLoading from '../../assets/images/loader.gif';
import config from '../../global/config';
import logoGiro from '../../assets/images/logo_giro_fundo.png';
import SweetAlert from 'react-bootstrap-sweetalert';

import ReCaptcha from 'react-google-recaptcha';

const DetranESScreen = () => {
  const [isLoading, setIsLoading] = useState(true);

  const [placa, setPlaca] = useState('');
  const [renavam, setRenavam] = useState('');
  const [cpf, setCpf] = useState('');

  const [debits, setDebits] = useState({});
  const [message, setMessage] = useState('');
  const [message2, setMessage2] = useState('');
  const [debitsChecked, setDebistChecked] = useState([]);
  const [totalPay, setTotalPay] = useState(0.0);
  const [alertMsg, setAlertMsg] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertType, setShowAlertType] = useState('');

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const recaptchaRef = React.createRef();

  const getDebits = async () => {
    let capt = await recaptchaRef.current.executeAsync();

    fetch(
      config.apiServerDetran +
        '/api/Detran/GetDebitosDetran?placa=' +
        placa +
        '&renavam=' +
        renavam +
        '&detran=3',
      {
        headers: { credentials: capt },
      }
    )
      .then((id) => id.json())
      .then((value) => {
        setDebits(value);

        if (value.length === 0) setMessage('Nenhum débito encontrado.');
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showToast = (msg, type) => {
    setShowAlert(true);
    setAlertMsg(msg);
    setShowAlertType(type);
  };

  const onSubmit = () => {
    if (placa === '') {
      showToast('Por favor, informar a Placa', 'warning');
      return;
    }
    if (renavam === '') {
      showToast('Por favor, informar o Renavam', 'warning');
      return;
    }

    setIsLoading(true);
    setMessage('');
    setTotalPay(0.0);
    setDebits({});
    setDebistChecked([]);

    getDebits();
  };

  const getLink = () => {
    const data = {
      placa: placa,
      renavam: renavam,
      cpfCnpj: cpf,
      debitos: debitsChecked,
    };

    setIsLoading(true);

    fetch(config.apiServerDetran + '/api/DetranES/GetLink', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        let link = `/detranescheck/${res.id}`.replace('"', '').replace('"', '');

        if (res.erro === null) window.location.href = link;
        else {
          setMessage2(res.erro);
          setIsLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsLoading(false);
      });
  };

  const onHandleChange = (e, field) => {
    let val = e.target.value;

    if (field === 'placa') {
      val = val.toUpperCase();

      val = val.replace(/(^[A-Z]{3})(\d{1})/, '$1$2');
      val = val.replace(/([A-Z0-9]{1})(\d)/, '$1$2').substr(0, 7);

      setPlaca(val);

      var regexPlaca = new RegExp('[A-Z]{3}[0-9][0-9A-Z][0-9]{2}');

      if (regexPlaca.test(val)) e.target.className = 'TextBox';
      else e.target.className = 'textbox-error';
    }

    if (field === 'renavam') {
      val = val.replace(/\D/g, '');

      setRenavam(val);
    }

    if (field === 'cpf') {
      val = val.replace(/\D/g, '');

      val = val.replace(/(\d{3})(\d)/, '$1.$2');
      val = val.replace(/(\d{3})(\d)/, '$1.$2');
      val = val.replace(/(\d{3})(\d{1,2})/, '$1-$2');
      val = val.replace(/(-\d{2})\d+?$/, '$1');

      setCpf(val);
    }
  };

  const handleCheckboxClick = (e) => {
    let selecteds = debitsChecked;

    if (e.target.checked) selecteds.push(e.target.value);

    if (!e.target.checked)
      selecteds.splice(selecteds.indexOf(e.target.value), 1);

    if (e.target.id.includes('Licenciamento Anual')) {
      selecteds = [];

      debits.forEach((item) => {
        let chk = document.getElementById(item.descricaoTributo);
        chk.checked = e.target.checked;

        if (!chk.id.includes('Licenciamento Anual'))
          chk.disabled = e.target.checked;

        if (chk.checked) selecteds.push(chk.value);
        else selecteds.splice(chk.value);
      });
    }

    setDebistChecked(selecteds);

    let totalPay = 0;
    selecteds.forEach((item) => {
      let debit = debits.find(
        (f) => f.idTransacaoBoletoDetran.toString() === item.toString()
      );
      totalPay += debit.valor;
    });
    setTotalPay(totalPay);
  };

  return (
    <>
      <div className="main-area" id="main-area">
        <div className="main-section">
          <Row>
            {
              <Col sm={6} xs={12}>
                {debits.length > 0 ? (
                  <>
                    <h5
                      style={{
                        fontWeight: 'bold',
                        paddingLeft: '10px',
                        paddingBottom: '10px',
                        textAlign: 'justify',
                      }}
                    >
                      Selecione os débitos abaixo que deseja pagar.
                    </h5>
                    <h6
                      style={{
                        fontWeight: 'bold',
                        paddingLeft: '10px',
                        paddingBottom: '10px',
                        textAlign: 'justify',
                      }}
                    >
                      Observação: Caso selecione o débito "Licenciamento Anual",
                      todos os outros débitos serão selecionados
                      automaticamente.
                    </h6>
                    <div
                      style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        boxShadow:
                          '1px 1px 2px #999999, 0 0 25px #999999, 0 0 5px #999999',
                      }}
                    >
                      <Table style={{ backgroundColor: 'white' }} striped hover>
                        <thead>
                          <tr style={{ fontWeight: '700' }}>
                            <td></td>
                            <td>Débito</td>
                            <td style={{ textAlign: 'right' }}>Valor</td>
                            <td style={{ textAlign: 'right' }}>Vencimento</td>
                          </tr>
                        </thead>
                        <tbody>
                          {debits.map((item, index) => (
                            <tr key={index}>
                              <td style={{ textAlign: 'center' }}>
                                <input
                                  type="checkbox"
                                  style={{
                                    cursor: 'pointer',
                                    width: '1.1rem',
                                    height: '1.1rem',
                                  }}
                                  id={item.descricaoTributo}
                                  value={item.idTransacaoBoletoDetran}
                                  onChange={(e) => handleCheckboxClick(e)}
                                />
                              </td>
                              <td>{item.descricaoTributo}</td>
                              <td style={{ textAlign: 'right' }}>
                                {item.valor.toLocaleString('pt-br', {
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                              <td style={{ textAlign: 'right' }}>
                                {item.dataVencimento}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <h2>
                        Total: R${' '}
                        {totalPay.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                        })}
                      </h2>
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div
                        className="cancel-button padding-top-3"
                        style={{ textAlign: 'center' }}
                      >
                        <button
                          style={{
                            maxWidth: '120px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }}
                          onClick={() => (window.location.href = '/detranes')}
                        >
                          <b>Cancelar</b>
                        </button>
                      </div>
                      <div
                        className="confirm-button padding-top-3"
                        style={{ textAlign: 'center' }}
                      >
                        <button
                          style={{
                            maxWidth: '120px',
                            marginTop: '20px',
                            marginBottom: '10px',
                          }}
                          onClick={() => getLink()}
                        >
                          <b>Continuar</b>
                        </button>
                      </div>
                    </div>
                    <div>{message2}</div>
                  </>
                ) : (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <h6
                        style={{
                          fontWeight: 'bold',
                          paddingLeft: '10px',
                          paddingBottom: '10px',
                          textAlign: 'justify',
                        }}
                      >
                        Informe a Placa e o Renavam para consultar seus débitos
                      </h6>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <input
                        type="text"
                        style={{
                          textAlign: 'center',
                          maxWidth: '150px',
                          marginTop: '5px',
                          outline: 0,
                        }}
                        value={placa}
                        onChange={(e) => onHandleChange(e, 'placa')}
                        className="TextBox"
                        placeholder="Placa"
                      ></input>
                      <input
                        inputMode="numeric"
                        type="text"
                        style={{
                          textAlign: 'center',
                          maxWidth: '150px',
                          marginTop: '5px',
                          outline: 0,
                          marginRight: '15px',
                          marginLeft: '15px',
                        }}
                        value={renavam}
                        onChange={(e) => onHandleChange(e, 'renavam')}
                        className="TextBox"
                        maxLength="11"
                        placeholder="Renavam"
                      ></input>
                      {/* <input
                        inputMode='numeric'
                        type='text'
                        style={{ textAlign: 'center', maxWidth: '130px', marginTop: '5px', outline: 0 }}
                        value={cpf}
                        onChange={(e) => onHandleChange(e, 'cpf')}
                        className="TextBox"
                        placeholder="CPF"
                      >
                      </input> */}
                    </div>
                    <br></br>
                    <div
                      className="confirm-button padding-top-3"
                      style={{ textAlign: 'center' }}
                    >
                      <button
                        style={{ maxWidth: '600px', marginBottom: '20px' }}
                        onClick={() => onSubmit()}
                      >
                        <b>Consultar débitos</b>
                      </button>
                    </div>
                    <div>{message}</div>
                  </>
                )}
              </Col>
            }
            {
              <Col sm={6} xs={{ offset: 12, size: 'flex' }}>
                <div className="Image-Center">
                  <img
                    className="Responsive"
                    src={logoGiro}
                    alt="Logo Giro Pagamentos"
                  ></img>
                </div>
              </Col>
            }
          </Row>
        </div>
      </div>
      <div className="sub-footer"></div>
      {isLoading && (
        <div className="loading">
          <div className="loaderImg">
            <img src={gitLoading} alt="loading" />
            <span className="fa fa-lock" style={{ fontSize: 24 }}></span>
          </div>
          <label style={{ textAlign: 'center', marginTop: 30, width: 300 }}>
            Processando consulta no Detran.
          </label>
        </div>
      )}
      {showAlertType === 'success' ? (
        <SweetAlert
          success
          title={alertMsg}
          show={showAlert}
          onConfirm={() => setShowAlert(false)}
          onCancel={() => setShowAlert(false)}
        ></SweetAlert>
      ) : (
        <SweetAlert
          warning
          title={alertMsg}
          show={showAlert}
          onConfirm={() => setShowAlert(false)}
          onCancel={() => setShowAlert(false)}
        ></SweetAlert>
      )}
      <ReCaptcha
        ref={recaptchaRef}
        size="invisible"
        sitekey="6Lf0ENAaAAAAAKncmTAeTKB3O9u3tDiRLxdfqG7z"
      />
    </>
  );
};

export default DetranESScreen;
